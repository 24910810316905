.TokenScreen {
    position: relative;
    height: calc(100vh - 55px);
    /* top: 50%; */
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
    align-items: center;
}

.TokenScreen .TokenScreen-title {
    color: white;
}

.TokenScreen button {
    margin-top: 10px;
}

.TokenScreen .TokenScreen-disabledBtn {
    opacity: 0.8;
}

.TokenScreen .TokenScreen-header {
    color: white;
    display: flex;
    flex-flow: column;
    justify-content: center;
    font-size: var(--font-size-xl);
    margin-bottom: 20px;
    margin-top: 20px;
}

.TokenScreen .TokenScreen-header >span:last-child {
    font-size: var(--font-size-l);
}

.TokenScreen .TokenScreen-email {
    color: #FFF;
    font-size: var(--font-size-l);
    margin-left: 5px;
}

.TokenScreen .TokenScreen-helpBlock {
    margin-top: 10px;
    color: #FFF;
    font-size: var(--font-size-l);
}

.TokenScreen .TokenScreen-helpBlock p {
    margin: 5px 0;
}

.TokenScreen .TokenScreen-contactUs {
    margin: 0;
}

.TokenScreen .homeimg img {
    width: 100%;
    height: 100%;
    box-shadow: var(--shadow-black-100);
    border-radius: 32px;
}

.TokenScreen .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.TokenScreen .homeimg {
    position: relative;
    z-index: 1;
}

.TokenScreen .homeimg .circle {
    z-index: 1;
    width: 400px;
    height: 400px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    position: relative;
}

.TokenScreen .homeimg .circle::before {
    content: "";
    position: absolute;
    width: 60px;
    height: 60px;
    background-color: rgba(190, 184, 184, 0.4);
    border-radius: 50%;
    left: 30px;
    top: 30px;
    right: 10px;
    transform-origin: 170px 170px;
    animation: spin_01 10s linear infinite;
   
}
.TokenScreen .img{
    /* max-width: 50%;
    max-height: 50%; */
    width: 50%;
    height: 50%;
}

.TokenScreen .ant-input {
    font-weight: normal;
    outline: 0;
    border: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fff;
    color: #333;
    border-radius: 0;
    padding: 12px 10px 10px 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #fff;
    width: 100%;
    border: 1px solid;
    border-color: inherit;
}

@keyframes spin_01 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.downloadappitem {
    background-color: var(--black-000);
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    box-shadow: var(--shadow-black-300);
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1178px) {
    .TokenScreen .homeimg .circle {
        z-index: 1;
        width: 250px;
        height: 250px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 50%;
    }

    .TokenScreen .homeimg .circle::before {
        content: "";
        position: absolute;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        left: 15px;
        top: 0px;
        transform-origin: 110px 125px;
        animation: spin_01 10s linear infinite;
    }
}

@media screen and (max-width: 800px) {
    .TokenScreen {
        flex-flow: column-reverse;
        justify-content: center;
    }
}

@media screen and (max-width: 300px) {
    .TokenScreen .homeimg {
        display: none;
    }
}