.FlightInfo {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-family:
        -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
}

.FlightInfo .FlightInfo-directionPoint {
    display: flex;
    flex-flow: column nowrap;
    text-align: var(--text-align);
}

.FlightInfo .FlightInfo-directionPoint span:first-child {
    font-size: var(--font-size-xl);
    line-height: 26px;
}

.FlightInfo .FlightInfo-directionPoint span:last-child {
    font-size: var(--font-size-m);
}

.FlightInfo .FlightInfo-planeContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 0 15px;
}

.FlightInfo .FlightInfo-flight {
    position: relative;
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.FlightInfo .FlightInfo-point {
    width: var(--small-point-size);
    height: var(--small-point-size);
    border-radius: 50%;
    background: #000;
    transition: opacity 0.1s;
}

.FlightInfo .FlightInfo-line {
    border-bottom: 2px dotted var(--carrier-background);
    flex: 1;
    height: 4px;
}

.FlightInfo .FlightInfo-plane {
    width: 20px;
    height: 20px;
    position: absolute;
    left: -2px;
    top: -7px;
}

.FlightInfo .FlightInfo-duration {
    width: 100%;
    text-align: center;
}