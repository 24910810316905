.NonceScreen {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 400px;
    padding: 10px;
    overflow: hidden;
    margin-top: 20px;
    flex: 1
}

.NonceScreen .NonceScreen-contaner {
    margin: 20px 0;
    max-width: 650px;
}

.NonceScreen .NonceScreen-Steps-Container {
    height: 100%;
}

.NonceScreen .NonceScreen-description {
    white-space: pre-line;
    font-size: 16px;
}

.NonceScreen .NonceScreen-contaner .NonceScreen-Form .NonceScreen-Form-container {
    background: var(--gray-background);
    padding: 15px;
    margin-bottom: 20px;
}

.NonceScreen .NonceScreen-contaner .NonceScreen-Form .NonceScreen-Label {
    font-size: 16px;
    font-weight: 500;
}

.NonceScreen .NonceScreen-Steps {
    justify-content: center;
}