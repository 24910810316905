.BookingCard {
    margin-bottom: 20px;
}

.BookingCard .BookingCard-title {
    margin: 0px;
    text-transform: uppercase;
}

.BookingCard .BookingCard-text {
    font-size: 16px;
}

.BookingCard .BookingCard-text.denotation {
    font-weight: bold;
}

.BookingCard .BookingCard-passenger {
    margin-bottom: 10px;
}

.BookingCard .BookingCard-adl {
    white-space: pre-line;
}