.FlightInformationTwoDirect {
    display: flex;
    flex-direction: row;
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.FlightInformationTwoDirect-routes {
    width: 75%;
    position: relative;
}

.FlightInformationTwoDirect {
    margin-bottom: 0px;
}

.FlightInformationTwoDirect.white {
    background: var(--gray-background);
    padding: 10px;
    margin-bottom: 30px;
}

.FlightInformationTwoDirect .Text {
    font-size: 16px;
    font-weight: bold;
}

.FlightInformationTwoDirect .ant-typography {
    color: #333333 !important;
    margin: 0;
}

@media (max-width: 597px) {
    .FlightInformationTwoDirect .Text {
        font-size: 14px;
    }

    h1.ant-typography {
        font-size: 28px;
    }
}

.FlightInformationTwoDirect .Route-icon {
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    letter-spacing: -1ex;
    text-indent: -9999px;
    text-decoration: none;
    line-height: 1;
    padding: 0;
    border: none;
    outline: none;
}

.FlightInformationTwoDirect .Route-icon::before {
    background: url(../../assets/images/sprite.png) no-repeat -38px -113px;
    width: 35px;
    height: 27px;
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -14px;
    left: 50%;
    margin-left: -18px
}
