.errorText {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 0px;
}

.PassengerForm {
    display: flex;
    flex-direction: column;
}

.PassengerForm .react-international-phone-input-container .react-international-phone-country-selector-button,
.PassengerForm .react-international-phone-input-container .react-international-phone-input {
    /* line-height: 1.375; */
    height: 50px;
    font-size: 16px;
    /* font-weight: normal; */
    outline: 0;
    border: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fff;
    color: #333;
    border-radius: 0;
    padding: 12px 10px 10px 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #fff;
    width: 100%;
    border: 1px solid;
    border-color: inherit;
}

.PassengerForm .react-international-phone-input-container .react-international-phone-country-selector-button {
    border-right: none;
}

.PassengerForm .react-international-phone-input-container .react-international-phone-input {
    border-left: none;
}

.PassengerForm .ant-input,
.PassengerForm .ant-picker,
.PassengerForm .ant-select {
    font-weight: normal;
    outline: 0;
    border: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fff;
    color: #333;
    border-radius: 0;
    padding: 12px 10px 10px 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #fff;
    width: 100%;
    border: 1px solid;
    border-color: inherit;
}

.PassengerForm .ant-picker input {
    opacity: 1;
}

.PassengerForm .ant-input-outlined:focus,
.PassengerForm .ant-picker:focus,
.PassengerForm .ant-input-outlined:hover,
.PassengerForm .ant-picker-outlined:hover,
.PassengerForm .ant-select:focus,
.PassengerForm .ant-select:hover {
    border-color: #3651fe;
}

.mobile .ant-picker-input>input::selection {
    background: none;
    color: inherit;
}

.mobile .ant-picker-input>input::-moz-selection {
    background: none;
    color: inherit;
}

.mobile .ant-picker-input>input {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    caret-color: transparent;
}

.MainForm-Collapse {
    background: #e1e1e1;
    border-radius: 0;
    border: 0;
    margin-bottom: 10px;
    font-size: 16px
}

.ant-collapse .ant-collapse-content {
    background: var(--gray-background);
    border-radius: 0 !important;
}