.OptionsInfo {
    display: flex;
    flex-direction: column;
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
    padding: 0px 10px;
    margin-bottom: 30px;
}

.options-title {
    margin-bottom: 0px;
}

.OptionsInfo-Row {
    border-bottom: 1px solid #FFF;
    background: var(--gray-background);
    padding: 10px;
}

.OptionsInfo-Col {
    border-right: 1px solid #FFF;
}

.price-text {
    font-size: 16px;
    font-weight: bold;
    text-align: end;
}

.bold-text {
    font-size: 16px;
    font-weight: bold;
}

.subtotal-options-row {
    padding-bottom: 10px;
    padding: 10px;
    background: #CCC;
}

.subtotal-title {
    margin: 0px !important;
}

.total-price-row {
    padding-bottom: 10px;
    padding: 10px;
    background: #767676;
}

.total-title {
    margin: 0 !important;
    color: #FFF !important;
}
