.Tickets {
    width: 100%;
    margin-top: 20px;
}

.IframeValidator {
    position: relative;
}

.IframeValidator iframe {
    width: 100%;
    height: 950px;
    border: none;
}