.Loader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    background: #FFF;
    z-index: 10000;
    opacity: 0.6;
}