.Wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.Wrapper.auth {
    background: var(--carrier-background);
}

.Wrapper .Wrapper-container {
    max-width: 1300px;
    width: 100%;
    padding: 0 10px 10px;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
}

.Wrapper .Wrapper-top {
    width: 100%;
    display: flex;
    gap: 10px;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--gray-background);
    box-sizing: border-box;
    padding: 0 10px;
}

.Wrapper-image {
    position: relative;
    /* background-color: #E40421; */
    height: 100%;
    max-height: 45vh;
}

.Wrapper-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Wrapper .Wrapper-footer {
    min-height: 70px;
    margin-top: 20px;
    border-top: 1px solid #CCC;
    display: flex;
    align-items: center;
    padding: 10px 20px;

}

.Wrapper .Wrapper-booking {
    color: #000
}

.ant-drawer .anticon svg {
    /*color: #FFF*/
}

.Wrapper .Booking .ant-drawer-content-wrapper {
    height: 100svh !important;
}

.Wrapper .Sticky-Drawer {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 10;
}