.BookingsScreen {
    position: relative;
    padding: 20px 30px;
    background-color: var(--gray-background);
}

.BookingsScreen .ant-input {
    font-weight: normal;
    outline: 0;
    border: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fff;
    color: #333;
    border-radius: 0;
    padding: 12px 10px 10px 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #fff;
    width: 100%;
    border: 1px solid;
    border-color: inherit;
}