.TicketInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    max-width: 650px !important;
    background-color: #FFF;
    width: 100%;
}

.TicketInfo .TicketInfo-Description {
    font-size: 16px;
}

.TicketInfo .ant-radio-group .ant-radio-wrapper {
    font-size: 18px;
}