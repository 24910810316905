.Header {
    display: flex;
    flex-flow: row nowrap;
    padding-bottom: 10px;
}

.Header .Header-logoContainer {
    font-size: var(--font-size-xl);
    display: flex;
    flex-flow: row wrap;
    cursor: pointer;
}

.Header .Header-logo {
    width: 80px;
    height: 80px;
}

.Header .Header-headerCol {
    color: white;
    display: flex;
    flex-flow: column;
    justify-content: center;
    color: var(--carrier-background);
}

.Header .Header-headerCol >span:last-child {
    font-size: var(--font-size-l);
}

.BoardingPassDriver-footer {
    color: white;
}

.ant-radio-checked .ant-radio-inner {
    border-color: #FFF !important;
}

@media screen and (max-width: 1024px) {
    .Header {
        flex-flow: column nowrap;
    }
}