.PassengerForm-CheckIn {
    min-height: 400px;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.PassengerForm-CheckIn .PassengerForm-contaner {
    margin: 20px 0;
    max-width: 650px;
}

.PassengerForm-CheckIn .PassengerForm-description {
    white-space: pre-line;
    font-size: 16px;
}

.PassengerForm-CheckIn .PassengerForm-contaner .PassengerForm-Form .PassengerForm-Form-container {
    background: var(--gray-background);
    padding: 15px;
    margin-bottom: 20px;
}

.PassengerForm-CheckIn .PassengerForm-contaner .PassengerForm-Form .PassengerForm-Label {
    font-size: 16px;
    font-weight: 500;
}

.PassengerForm-CheckIn .react-international-phone-input-container .react-international-phone-country-selector-button,
.PassengerForm-CheckIn .react-international-phone-input-container .react-international-phone-input {
    height: 50px;
    font-size: 16px;
    outline: 0;
    border: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fff;
    color: #333;
    border-radius: 0;
    padding: 12px 10px 10px 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #fff;
    width: 100%;
    border: 1px solid;
    border-color: inherit;
}

.PassengerForm-CheckIn .ant-input,
.PassengerForm-CheckIn .ant-picker {
    font-weight: normal;
    outline: 0;
    border: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fff;
    color: #333;
    border-radius: 0;
    padding: 12px 10px 10px 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #fff;
    width: 100%;
    border: 1px solid;
    border-color: inherit;
}

.PassengerForm-CheckIn .ant-picker input {
    opacity: 1;
}

.PassengerForm-CheckIn .react-international-phone-input-container .react-international-phone-country-selector-button {
    border-right: none;
}

.PassengerForm-CheckIn .react-international-phone-input-container .react-international-phone-input {
    border-left: none;
}

.mobile .ant-picker-input>input::selection {
    background: none;
    color: inherit;
}

.mobile .ant-picker-input>input::-moz-selection {
    background: none;
    color: inherit;
}

.mobile .ant-picker-input>input {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    caret-color: transparent;
}