.IframeDrawer {
    position: relative;
}

.IframeDrawer-header {
    background-color: #767676;
    font-size: 24px;
    color: #FFF;
}

.IframeDrawer-header .ant-drawer-title {
    font-size: 22px;
}

.IframeDrawer-header .anticon svg {
    color: white;
}

.IframeDrawer-content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.IframeDrawer-content iframe {
    width: 100%;
    max-width: 1024px;
    height: 950px;
    border: none;
}