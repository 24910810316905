.SecurityStatement {
    margin: 0 auto;
    max-width: 950px;
    width: 100%;
}

.SecurityStatement .SecurityStatement-Warning {
    width: 100%;
    background: #f1f0a3;
    padding: 20px;
    margin-bottom: 20px;
}

.SecurityStatement .SecurityStatement-Paragraph {
    font-size: 16px;
}

.SecurityStatement .SecurityStatement-Warning .SecurityStatement-Col {
    max-width: 150px;
}

.SecurityStatement .SecurityStatement-Warning .icon-description {
    text-align: center;
}

.SecurityStatement .SecurityStatement-Warning span {
    height: 118px;
}

.SecurityStatement .SecurityStatement-Warning span::before {
    display: block;
    height: 114px;
    /* width: 100%; */
    content: "";
    /* position: absolute; */
}

.SecurityStatement .SecurityStatement-Warning .poisons-toxins::before {
    background: url('../../assets/prohibitory_icons.png') no-repeat 16px -1px;
}

.SecurityStatement .SecurityStatement-Warning .oxidizing-agents:before {
    background: url('../../assets/prohibitory_icons.png') no-repeat -128px -5px;
}

.SecurityStatement .SecurityStatement-Warning .corrosives:before {
    background: url('../../assets/prohibitory_icons.png') no-repeat -265px -5px;
}

.SecurityStatement .SecurityStatement-Warning .fireworks:before {
    background: url('../../assets/prohibitory_icons.png') no-repeat -405px -5px;
}

.SecurityStatement .SecurityStatement-Warning .lighter-fuel:before {
    background: url('../../assets/prohibitory_icons.png') no-repeat -545px -5px;
}

.SecurityStatement .SecurityStatement-Warning .compressed-gas:before {
    background: url('../../assets/prohibitory_icons.png') no-repeat 12px -135px;
}

.SecurityStatement .SecurityStatement-Warning .flammable-gas:before {
    background: url('../../assets/prohibitory_icons.png') no-repeat -128px -135px;
}

.SecurityStatement .SecurityStatement-Warning .readioactive-materials:before {
    background: url('../../assets/prohibitory_icons.png') no-repeat -265px -135px;
}

.SecurityStatement .SecurityStatement-Warning .infectious-substance:before {
    background: url('../../assets/prohibitory_icons.png') no-repeat -404px -135px;
}

.SecurityStatement .SecurityStatement-Warning .magnetic-materials:before {
    background: url('../../assets/prohibitory_icons.png') no-repeat -541px -135px;
}

.SecurityStatement .SecurityStatement-List {
    margin-left: 25px;
}

.SecurityStatement .SecurityStatement-List li {
    list-style: disc;
}