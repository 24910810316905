.Option {
    min-height: 520px;
    display: flex;
    flex-direction: column;
    background: var(--gray-background);
    border-radius: 0;
    cursor: pointer;
}

.Option .ant-card-cover {
    overflow: hidden;
}

.Option .ant-card-cover img {
    border-radius: 0px;

}

.Option .ant-card-body {
    flex-grow: 1;
}

.Option .ant-card-actions {
    margin-top: auto;
    border-top: none;
    background: var(--gray-background);
}

.Option .ant-card-actions li {
    text-align: end;
}

.Option:hover img {
    transform: scale(1.1);
    transition: transform 1s ease;
}