.OutboundScreen {

}

.OutboundSeatsClassCards-accordion {
    overflow-x: auto;
}

.OutboundSeatsClassCards {
    width: 100%;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.OutboundScreen .OutboundScreen-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.OutboundScreen-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px;
}

.OutboundScreen-slider {
    width: 100%;
}

.OutboundScreen-slider .ibe_flight_selection {
    background-color: #0E4194;
    color: #DBE3EF;
    width: 100%;
    min-width: 30em;
}

.OutboundScreen-slider .ibe_flight_selection--number {
    text-align: center;
    padding-right: 0px;
}

.OutboundScreen-slider .ibe_flight_selection_duration-circle-anim-plane:after,
.OutboundScreen-slider .ibe_flight_selection_duration-circle-anim-plane.transit-point-0:after {
    background: url(../../assets/images/global/1x--sprite.png) no-repeat -226px -109px;
    width: 14px;
    height: 14px;
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -7px;
    left: 50%;
    margin-left: -7px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 0;
}

.OutboundScreen-slider .ibe_flight_selection_duration-circle-anim-plane.transit-point-1:after {
    background: url(../../assets/images/global/1x--sprite.png) no-repeat -226px -109px;
    width: 14px;
    height: 14px;
    content: "";
    position: absolute;
    margin-top: 0px;
    left: 35%;
    margin-left: -10px;
    -webkit-transform: rotate(26deg);
    transform: rotate(26deg);
    top: 0;
}

.OutboundScreen-slider .ibe_flight_selection_duration-circle-anim-plane.transit-point-2:after {
    background: url(../../assets/images/global/1x--sprite.png) no-repeat -226px -109px;
    width: 14px;
    height: 14px;
    content: "";
    position: absolute;
    margin-top: 20px;
    left: 20%;
    margin-left: -13px;
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
    top: 0;
}

.OutboundScreen-slider .tooltip .tooltip--icon:after {
    background: url(../../assets/images/global/1x--sprite.png) no-repeat -22px -182px;
    width: 20px;
    height: 20px;
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -10px;
    left: 50%;
    margin-left: -10px;
}
