.ShortFlightInfo {
    width: 100%;
    max-width: 450px;
    border: 1px solid rgb(240, 240, 240);
    padding: 15px;
}

.ShortFlightInfo .ShortFlightInfo-Text {
    font-size: 16px;
}

.ShortFlightInfo .ShortFlightInfo-Text.bold {
    font-weight: 500;
}

.ShortFlightInfo .ShortFlightInfo-Flight,
.ShortFlightInfo .ShortFlightInfo-Number {
    border: 1px solid rgb(240, 240, 240);
    padding: 10px
}

.ShortFlightInfo-Number .ShortFlightInfo-Text.bold {
    font-size: 22px;
    letter-spacing: 10px;
}

.ShortFlightInfo .ShortFlightInfo-Number {
    background: #00000014;
}


.ShortFlightInfo .FlightInfo .FlightInfo-plane {
    left: calc(50% - 10px);
}