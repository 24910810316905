.PlaneSeating {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    border-radius: var(--border-radius);
    background: #FFF;
    position: relative; 
}

.PlaneSectionSeating {
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.PlaneSeating-planeMap {
    #position: absolute;
}

.PlaneSectionSeating-header {
    display: flex;
    flex-direction: row;
    position: absolute;
}

.PlaneSectionSeating-rows {
    display: flex;
    flex-direction: row;
    position: absolute;
}

.PlaneSectionSeating-row {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.PlaneSectionSeating-index {
    left: -35px;
    top: -20px;
    position: absolute;
}

.PlaneSectionSeating-header-item {
    position: absolute;
}

.PlaneSectionSeating-btn {
    width: 26px;
    height: 40px;
    padding: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
}