.SearchBooking {
    max-width: 950px;
    width: 100%;
    margin: 0 auto;
}

.SearchBooking .SearchBooking-Header {
    padding-bottom: 40px;
    padding-top: 40px;
}

.SearchBooking .SearchBooking-Header h1 {
    margin-bottom: 0;
}

.SearchBooking .SearchBooking-Desc {
    font-size: 17px;
    opacity: .85;
}

.SearchBooking .SearchBooking-Link {
    justify-content: flex-start;
    padding: 0;
    font-size: 17px;
    margin-bottom: 15px;
}

.SearchBooking-Form-Container {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 30px;
}

.SearchBooking-Form-Container .ant-form-item-required {
    font-size: 16px !important;
}

.SearchBooking-Form-Container .SearchBooking-Form {
    padding: 60px 20px !important;
    background: #FFF;
    box-shadow: 0px 14px 80px rgba(0, 0, 0, 0.05), 0px 7.0875px 34.875px rgba(0, 0, 0, 0.03375), 0px 2.8px 13px rgba(0, 0, 0, 0.025), 0px 0.6125px 4.625px rgba(0, 0, 0, 0.01625);
    transition: box-shadow 0.25s cubic-bezier(0.22, 0.61, 0.36, 1), transform 0.25s cubic-bezier(0.22, 0.61, 0.36, 1);
    align-items: flex-start;
}

.SearchBooking-Form-Container .SearchBooking-Form .ant-form-item {
    width: 100%;
}

.SearchBooking-Form-Container .SearchBooking-Form .ant-input {
    font-weight: normal;
    outline: 0;
    border: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fff;
    color: #333;
    border-radius: 0;
    padding: 12px 10px 10px 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #fff;
    width: 100%;
    border: 1px solid;
    border-color: inherit;
}

.SearchBooking-Form-Container .SearchBooking-Tips {
    background-image: url('../../../../assets/images/tanzania.webp');
    height: 100%;
    min-height: 350px;
    padding-bottom: 20px;
    cursor: pointer;
    background-size: cover;
}


.SearchBooking-Form-Container .SearchBooking-Tips:hover {
    transform: scale(1.010);
    transition: transform 0.2s ease;
}

@media screen and (max-width: 595px) {
    .SearchBooking .SearchBooking-Header {
        padding-bottom: 20px;
        padding-top: 40px;
    }

    .SearchBooking-Form-Container {
        margin-top: 20px;
    }
}