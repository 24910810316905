.SeatIcon-ECONOMY:after {
    background: url(./images/iconsSprite.png) no-repeat -112px 0px;
    width: 26px;
    height: 40px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -13px;
}

.SeatIcon-BUSY:after {
    background: url(./images/iconsSprite.png) no-repeat -56px -96px;
    width: 26px;
    height: 40px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -13px;
}

.SeatIcon-MY_SEAT:after {
    background: url(./images/iconsSprite.png) no-repeat -84px -54px;
    width: 26px;
    height: 40px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -13px;
}

.SeatIcon-ECONOMYSMALL:after {
    background: url(./images/iconsSprite.png) no-repeat -168px -166px;
    width: 26px;
    height: 28px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -13px;
}

.SeatIcon-ECONOMYSMALLBUSY:after {
    background: url(./images/iconsSprite.png) no-repeat -84px -96px;
    width: 26px;
    height: 28px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -13px;
}


.SeatIcon-MY_SEAT_SMALL:after {
    background: url(./images/iconsSprite.png) no-repeat -140px -166px;
    width: 26px;
    height: 28px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -13px;
}

.SeatIcon-EXITLEFT:after {
    background: url(./images/iconsSprite.png) no-repeat -30px -138px;
    width: 28px;
    height: 26px;
    content: "";
    position: absolute;
    top: 20%;
    margin-top: -20px;
    right: 20%;
}

.SeatIcon-EXITRIGHT:after {
    background: url(./images/iconsSprite.png)  no-repeat 0px -138px;
    width: 28px;
    height: 26px;
    content: "";
    position: absolute;
    top: 20%;
    margin-top: -20px;
    left: 100%;
}

.SeatIcon-BSBUSY:after {
    background: url(./images/iconsSprite.png) no-repeat 0px 0px;
    width: 26px;
    height: 52px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -13px;
}

.SeatIcon-BSEMPTY:after {
    background: url(./images/iconsSprite.png) no-repeat -28px 0px;
    width: 26px;
    height: 52px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -13px;
}

.SeatIcon-BSSEAT:after {
    background: url(./images/iconsSprite.png) no-repeat -56px 0px;
    width: 26px;
    height: 52px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -13px;
}


.SeatIcon {
    position: relative;
    color: #DBE3EF;
}

.SeatIcon-id {
    position: absolute;
    color: #DBE3EF;
    font-size: 14px;
    font-weight: bold;
    bottom: 7px;
    left: 22px;
    margin-left: -13px;
}
