.Flight {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.Flight:not(:first-child) {
    margin-top: 10px;
}

.Flight-class {
    border-width: 0px 0px 1px 0px;
    border-style: dashed;
    padding-left: 30px !important;
}

.Flight-direction {
    padding-left: 30px !important;
}

.Flight.dark .Flight-class,
.Flight.dark .Flight-direction {
    border-color: #333333;
}

.Flight.white .Flight-class,
.Flight.white .Flight-direction {
    border-color: #FFF;
}

.Flight:last-child .Flight-direction {
    border-bottom: 0px;
}