.BookingInfoDrawer {
    position: relative;
}

.BookingInfoDrawer-body .BookingCard-passenger .ant-drawer .anticon svg {
    color: green;
}


.BookingInfoDrawer-header {
    background-color: #767676;
    font-size: 24px;
    color: #FFF;
}

.BookingInfoDrawer-header .anticon svg {
    color: white;
}
