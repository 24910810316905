.Drawer {
    position: sticky;
    bottom: 0px;
}

.Drawer .ant-drawer-content-wrapper {
    position: sticky;
    bottom: 0px;
    height: auto;
}

.Drawer .ant-drawer-body {
    padding: 0 !important;
    background: #FFF;
    overflow-x: hidden;
}

.Drawer .ant-drawer-mask {
    position: fixed;
}

.Drawer .ant-drawer-header {
    border-bottom: 1px solid #FFF !important;
}

.Drawer .ant-drawer-footer {
    border-top: 1px solid #FFF !important;
}

.Drawer .ant-drawer-footer .ant-btn-link {
    color: #FFF !important;
}

.Drawer .ant-drawer-content {
    color: #FFF;
    max-height: 100svh;
}

.Drawer .ant-drawer-close svg {
    color: #FFF;
}

.Drawer .ant-radio-group {
    width: 100%;
}

.Drawer .ant-radio-group .ant-space {
    width: 100%;
}

.Drawer .ant-radio-group .ant-radio-wrapper {
    width: 100%;
    display: flex;
    max-width: 1024px;
    margin: 0 auto;
    border: 2px solid #c1c1c1;
    margin-bottom: 20px;
    padding: 0px 15px;
}

.Drawer .ant-radio-group .ant-radio-wrapper:first-child {
    margin-top: 20px;
}

.Drawer .ant-radio-group .ant-radio-wrapper span:not(:first-child) {
    flex: 1
}

.Drawer .ant-drawer-footer .ant-btn-default:disabled {
    border-color: #d9d9d9;
    color: #000;
    background: #CCC;
    opacity: 0.75;
}