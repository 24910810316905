.LanguageSelector {
    min-width: 80px;
}

.LanguageSelector .ant-select-selection-item {
    /* color: #FFF !important; */
}

.LanguageSelector .ant-select-arrow svg {
    /* color: #FFF; */
}