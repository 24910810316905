.Route {
    width: 100%;
    position: relative;
}

.Route-diraction {
    border-width: 0px 0px 1px 0px;
    border-style: dashed;
    padding-left: 30px;
}

.Route.dark .Route-diraction {
    border-color: #c1c1c1;
}

.Route.white .Route-diraction {
    border-color: #FFF;
}

.Route .Route-date {
    padding-left: 30px !important;
    border-width: 0px 0px 1px 0px;
    border-style: dashed;
    padding-left: 30px;
}

.Route.dark .Route-date {
    border-color: #c1c1c1;
}

.Route.white .Route-date {
    border-color: #FFF;
}

.Route:last-child .Route-date {
    border-bottom: 0px;
}

.Route .Route-icon {
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    letter-spacing: -1ex;
    text-indent: -9999px;
    text-decoration: none;
    line-height: 1;
    padding: 0;
    border: none;
    outline: none;
}

.Route .Route-icon::before {
    background: url(../../../assets/images/sprite.png) no-repeat -110px -113px;
    width: 30px;
    height: 10px;
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -5px;
    left: 50%;
    margin-left: -35px;
}

.Route .Route-divider.ant-divider-with-text::before,
.Route .Route-divider.ant-divider-with-text::after {
    width: 35%;
}