.IinitBookingScreen {}

.Switch {
    display: flex;
    justify-content: center;
    position: absolute;
    right: -15px;
    top: 30px;
    width: 32px;
    height: 32px;
    background: #3651fe;
    border-radius: 50%;
    z-index: 1;
    cursor: pointer;
}

.Switch.disabled {
    pointer-events: none;
    background: #576eff;
}

.Switch .Icon {
    color: #FFF
}

.Switch .Icon:disabled {
    pointer-events: none;
}

.ant-select-selection-search-input {
    font-size: 16px;
}

.ant-select-selector {
    border-width: 0px !important;
    background: #FFF;
    /* padding: 0px !important; */
    box-shadow: none !important;
}

.ant-select-focused {
    outline: none;
    border: none;
    box-shadow: none;
}

.ant-select-disabled .ant-select-selector {
    background: #FFF !important;
}

.ant-select-dropdown {
    border-radius: 0 !important;
}

.ant-radio-button-wrapper {
    background: none !important;
    border: none !important;
    color: #FFF !important;
    border-radius: 0 !important;
}

.ant-radio-button-wrapper:hover {
    color: #FFF !important;
}

.ant-radio-button-wrapper:first-child::before {
    position: absolute !important;
    content: "";
    background: url(../../assets/images/sprite.png) no-repeat -227px -125px !important;
    width: 10px !important;
    height: 14px !important;
    position: absolute;
    top: 8px !important;
    left: 0;
}

.ant-radio-button-wrapper::before {
    background: url(../../assets/images/sprite.png) no-repeat -143px -125px !important;
    width: 10px !important;
    height: 10px !important;
    position: absolute;
    top: 8px !important;
    left: 0;
}

.ant-radio-button-wrapper-checked {
    border-bottom: 2px solid #FFF !important;
    color: #FFF !important;
    background: none !important;
}

.mobile .ant-picker-input>input::selection {
    background: none;
    color: inherit;
}

.mobile .ant-picker-input>input::-moz-selection {
    background: none;
    color: inherit;
}

.mobile .ant-picker-input>input {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    caret-color: transparent;
}

@media screen and (max-width: 575px) {
    .Switch {
        right: calc(50% - 16px);
        top: 66px;
    }
}

@media screen and (max-width: 767px) {
    .Find-btn {
        width: 100%;
    }
}