.OutboundInfo {
    display: flex;
    flex-direction: column;
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
}

.OutboundInfo.white {
    background: var(--gray-background);
    padding: 10px;
    margin-bottom: 30px;
}

.OutboundInfo .Text {
    font-size: 16px;
    font-weight: bold;
}

.OutboundInfo .ant-typography {
    color: #333333 !important;
    margin: 0;
}

/* .OutboundInfo.white .ant-typography {
    color: #FFF !important;
    margin: 0;
}

.OutboundInfo.dark .ant-typography {
    color: #000 !important;
    margin: 0;
} */

.OutboundInfo .OutboundInfo-icon {
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    letter-spacing: -1ex;
    text-indent: -9999px;
    text-decoration: none;
    line-height: 1;
    padding: 0;
    border: none;
    outline: none;
}

.OutboundInfo .OutboundInfo-icon::before {
    background: url(../../assets/images/sprite.png) no-repeat -110px -128px;
    width: 30px;
    height: 10px;
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -5px;
    left: 50%;
    margin-left: -15px;
}

.ibe_flight_selection_duration-circle-anim-plane:after {
    background: url('../../assets/images/global/1x--sprite.png') no-repeat -221px -51px;
    background-size: 237px 218px;
}

.OutboundInfo.dark .OutboundInfo-icon::before {
    background: url(../../assets/images/sprite.png) no-repeat -110px -113px;
}

.OutboundInfo-flights {
    width: 75%;
    border-width: 0px 1px 0px 0px;
    border-style: solid;
}

.OutboundInfo.full .OutboundInfo-flights {
    width: 100%;
    border: none;
}

.OutboundInfo.dark .OutboundInfo-flights {
    border-color: #333333;
}

.OutboundInfo.white .OutboundInfo-flights {
    border-color: #FFF;
}

.OutboundInfo-flight {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.OutboundInfo-flight:not(:first-child) {
    margin-top: 10px;
}

.OutboundInfo-flight-direction {
    padding-left: 30px !important;
    border-bottom: 1px solid #333333
}

.OutboundInfo-flight:last-child .OutboundInfo-flight-direction {
    border-bottom: 0px;
}

.OutboundInfo-seats {
    width: 25%;
    padding-left: 15px;
}

@media (max-width: 597px) {
    .OutboundInfo .Text {
        font-size: 14px;
    }

    .OutboundInfo-flights {
        width: 100%;
        border-right: none;
    }

    .OutboundInfo-seats {
        padding-top: 10px;
        width: 100%;
        border-top: 1px solid #FFF;
    }

    .OutboundInfo-seats .ant-row {
        display: flex;
        flex-direction: column !important;
    }

    h1.ant-typography {
        font-size: 28px;
    }

    .ibe_flight_selection_duration-circle-anim-plane:after {
        transform: rotate(137deg);
    }
}