.InfoBlock {
    margin-bottom: 20px;
}

.InfoBlock thead tr th {
    background: none !important;
}

.InfoBlock thead tr th::before {
    width: 0px !important;
}

.InfoBlock tbody {
    background: var(--gray-background);
}

@media (max-width: 597px) {
    .InfoBlock thead tr th,
    .InfoBlock tbody tr td {
        padding: 8px 8px !important;
    }
}