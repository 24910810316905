.MonthsSlider {}

.MonthsSlider-container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    margin-top: 20px;
    position: relative;
}

.MonthsSlider-button {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 40px;
    background: rgb(255, 255, 255);
    color: #7c7c7c;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    z-index: 1;
    height: calc(100% - 6px);
}

.MonthsSlider-button.left {
    left: 0;
}

.MonthsSlider-button.right {
    right: 0;
}

.MonthsSlider-button.hidden {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
}

.MonthsSlider-button.visible {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.3s;
}

.MonthsSlider-wrapper {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    flex: 1;
    position: relative;
    width: 100%;
}

.MonthsSlider-content {
    display: flex;
    transition: transform 0.3s ease;
    width: max-content;
    scroll-behavior: smooth;
    padding-bottom: 6px;
}

.MonthsSlider-item {
    background-color: #E1E1E1;
    color: #767676;
    border: 1px solid #ddd;
    padding: 10px;
    cursor: pointer;
    font-size: 20px;
    min-width: 90px;
    height: 90px;
    margin: 0 2px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
}

.MonthsSlider-item:hover {
    background-color: #f0f0f0;
    transform: scale(1.02);
}

.MonthsSlider-item.active {
    background-color: #0E4194;
    color: #fff;
}

.MonthsSlider-item.active:after {
    content: '';
    position: absolute;
    bottom: -6px;
    border-bottom: none;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-top: 6px solid #0E4194;
    height: 0;
    width: 0;
    left: 50%;
    margin-left: -6px;
    z-index: 2;
}

.MonthsSlider .MonthsSlider-month {
    margin-bottom: 10px;
    font-weight: bold;
}

.MonthsSlider .MonthsSlider-month::before {
    background: url(../../assets/images/sprite.png) no-repeat -226px -56px;
    width: 14px;
    height: 14px;
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -7px;
    -webkit-transform: rotate(0deg);
    transform: rotate(90deg);
    top: 15px;
}

.MonthsSlider .MonthsSlider-item.active .MonthsSlider-month::before {
    background: url(../../assets/images/sprite.png) no-repeat -226px -109px;
    transform: rotate(0deg);
}

.MonthsSlider-content {
    overflow: auto;
}

.MonthsSlider-content {
    scrollbar-width: none;
}

.MonthsSlider-content::-webkit-scrollbar {
    display: none;
}