.BoardingPass {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
}

.BoardingPass .BoardingPass-Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;
}

.BoardingPass .BoardingPass-Header img {
    max-width: 350px;
    /* width: 100%; */
}

.BoardingPass .BoardingPass-Ticket {
    display: flex;
    flex-direction: column;
    border: 1px solid #000;
    border-radius: 35px;
    max-width: 950px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    margin-bottom: 20px;
}

.BoardingPass .BoardingPass-Ticket .BoardingPass-Ticket-Header {
    height: 75px;
    background-color: rgb(0 47 134);
}

.BoardingPass .BoardingPass-Ticket-Content {
    display: flex;
    margin: 25px;
}

.BoardingPass .BoardingPass-Ticket-Content .BoardingPass-Ticket-Info {
    flex: 1;
    padding: 10px 20px;
}

.BoardingPass .BoardingPass-Ticket-Content .BoardingPass-Ticket-Info .ant-typography {
    font-size: 16px;
}

.BoardingPass .BoardingPass-Ticket-Content .BoardingPass-Ticket-Info .BoardingPass-Ticket-Title.ant-typography {
    font-size: 24px;
    margin-top: 20px;
}

.BoardingPass .BoardingPass-Ticket-Content .BoardingPass-Ticket-Info .BoardingPass-Ticket-Row {
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
}

.BoardingPass .BoardingPass-Ticket-Content .BoardingPass-Ticket-Info .ant-row {
    margin-bottom: 10px;
}

.BoardingPass .BoardingPass-Ticket-Content .BoardingPass-Ticket-Info .BoardingPass-Ticket-Paragraph {
    margin-bottom: 10px;
    opacity: .75;
}

.BoardingPass .BoardingPass-Ticket-Content .BoardingPass-Ticket-Barcode {
    block-size: 70px;
    writing-mode: vertical-lr;
    display: flex;
    align-items: center;
    justify-content: center;
}

.BoardingPass .BoardingPass-Ticket-Content .BoardingPass-Ticket-Barcode img {
    transform: rotate(90deg);
    max-height: 100px;
}

.BoardingPass .BoardingPass-Notice {
    max-width: 950px;
    width: 100%;
    margin-top: 10px;
}

@media screen and (max-width: 574px) {
    .BoardingPass .BoardingPass-Ticket-Content .BoardingPass-Ticket-Info .ant-col {
        display: flex;
        flex-direction: row;
        gap: 10px
    }

    .BoardingPass .BoardingPass-Ticket-Content .BoardingPass-Ticket-Info .ant-row {
        row-gap: 0px !important;
    }
}

@media screen and (max-width: 740px) {
    .BoardingPass .BoardingPass-Header {
        flex-direction: column-reverse;

    }

    .BoardingPass .BoardingPass-Header img {
        max-width: calc(100% - 120px);
    }

    .BoardingPass .BoardingPass-Ticket-Content .BoardingPass-Ticket-Barcode {
        display: none;
    }
}

@media print {
    .BoardingPass .BoardingPass-Notice .ant-typography {
        font-size: 10px !important;
    }
}