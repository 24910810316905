.TicketInfo-booking {
    display: flex;
    flex-direction: column;
    max-width: 1240px;
    width: 100%;
    min-width: 100%;
    padding: 10px;
    background: var(--gray-background) !important;
    padding: 10px;
    margin-bottom: 10px;
}

.TicketInfo-booking .Text {
    font-size: 16px;
    font-weight: bold;
}

.TicketInfo-booking .ant-typography {
    color: #FFF !important;
    margin: 0;
}

.TicketInfo-booking .ant-typography {
    color: #000 !important;
    margin: 0;
}

.TicketInfo-booking .TicketInfo-booking-icon {
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    letter-spacing: -1ex;
    text-indent: -9999px;
    text-decoration: none;
    line-height: 1;
    padding: 0;
    border: none;
    outline: none;
}

.TicketInfo-booking .TicketInfo-booking-icon::before {
    background: url(../../../../assets/images/sprite.png) no-repeat -110px -128px;
    width: 30px;
    height: 10px;
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -5px;
    left: 50%;
    margin-left: -15px;
}

.ibe_flight_selection_duration-circle-anim-plane:after {
    background: url('../../../../assets/images/global/1x--sprite.png') no-repeat -221px -51px;
    background-size: 237px 218px;
}

.TicketInfo-booking.dark .TicketInfo-booking-icon::before {
    background: url(../../../../assets/images/sprite.png) no-repeat -110px -113px;
}

.TicketInfo-booking-flight {
    width: 75%;
    border-right: 1px solid #FFF;
}

.TicketInfo-booking-seats {
    width: 25%;
    padding-left: 15px;
}

@media (max-width: 597px) {
    .TicketInfo-booking .Text {
        font-size: 14px;
    }

    .TicketInfo-booking-flight {
        width: 100%;
        border-right: none;
    }

    .TicketInfo-booking-seats {
        padding-top: 10px;
        width: 100%;
        border-top: 1px solid #FFF;
    }

    .TicketInfo-booking-seats .ant-row {
        display: flex;
        flex-direction: column !important;
    }

    h1.ant-typography {
        font-size: 28px;
    }

    .ibe_flight_selection_duration-circle-anim-plane:after {
        transform: rotate(137deg);
    }
}