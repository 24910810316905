.Boarding {
    max-width: 750px;
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
}

.Boarding .Boarding-Text {
    font-size: 16px;
    text-align: start;
    opacity: .7;
}

.BoardingPass .ant-drawer-content-wrapper {
    height: 100svh !important;
}

@page
{
    size: A4;
    margin: 0;
}

@media print {
    .ant-drawer-header {
        display: none !important;
    }
}