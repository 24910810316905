.ant-picker-cell-selected .ant-picker-calendar-date {
    background: none !important;
}

.ant-picker-cell.ant-picker-cell-disabled {
    cursor: not-allowed;
    pointer-events: auto;
}

.ant-picker-cell .ant-picker-cell-inner {
    display: flex !important;
    flex-direction: column !important;
}

.ant-picker-cell.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: none !important;
}

.ant-picker-cell-inner.ant-picker-calendar-date {
    max-height: 80px !important;
    margin: 1px !important;
    background: var(--gray-background) !important;
}

.ant-picker-cell-inner.ant-picker-calendar-date.selected {
    background: #ffe6e6 !important
}

.ant-picker-cell-inner.ant-picker-calendar-date.selected-start-end {
    background: #ffe6e6 !important;
    border-top: 1px solid red !important;
}

.ant-picker-calendar-date-content.mobile {
    font-size: 12px;
}

.ant-picker-calendar-date-value.flight-date::after {
    background: url(../../../../assets//images/sprite.png) no-repeat -226px -56px;
    width: 16px;
    height: 16px;
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

@media (max-width: 597px) {
    .ant-picker-cell-inner.ant-picker-calendar-date {
        max-height: 50px !important;
    }
}