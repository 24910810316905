.LaunchScreen {
    flex: 1;
    position: relative;
    top: -90px;
}

.LaunchScreen .ant-tabs-tab {
    border-width: 0 !important;
    border-bottom: 1px solid var(--background) !important;
}

.LaunchScreen .ant-tabs-tab-active {
    border-bottom-color: #FFF !important;
}

.LaunchScreen .ant-tabs-nav::before {
    display: none !important;
}

.LaunchScreen .ant-tabs-content-holder {
    background: #FFF;
    border-radius: 0 var(--border-radius) var(--border-radius) var(--border-radius);
    padding: 8px 16px 6px;
}

.LaunchScreen .ant-picker-calendar-header {
    display: none;
}