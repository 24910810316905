:root {
    --background: #3651fe;
    --background-light: #3651feb3;
    --font-size-m: 13px;
    --font-size-l: 20px;
    --font-size-xl: 32px;
    --border-radius: 6px;

    --carrier-background: #E40421;

    --gray-background: #F2F2F2;

    --width-lg: 1024px;

    --text-align: left;

    --small-point-size: 6px;
}