.FlightInformation {
    display: flex;
    flex-direction: row;
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.FlightInformation-routes {
    width: 75%;
    position: relative;
}

.FlightInformation {
    margin-bottom: 0px;
}

.FlightInformation.white {
    background: var(--gray-background);
    padding: 10px;
    margin-bottom: 30px;
}

.FlightInformation .Text {
    font-size: 16px;
    font-weight: bold;
}

.FlightInformation .ant-typography {
    color: #333333 !important;
    margin: 0;
}

@media (max-width: 597px) {
    .FlightInformation .Text {
        font-size: 14px;
    }

    h1.ant-typography {
        font-size: 28px;
    }
}